import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1.50.0_babel-plugin_rkp5hcadkdur6dwkaz6svep3ee/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1.50.0_babel-plugin_rkp5hcadkdur6dwkaz6svep3ee/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1.50.0_babel-plugin_rkp5hcadkdur6dwkaz6svep3ee/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1.50.0_babel-plugin_rkp5hcadkdur6dwkaz6svep3ee/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1.50.0_babel-plugin_rkp5hcadkdur6dwkaz6svep3ee/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1.50.0_babel-plugin_rkp5hcadkdur6dwkaz6svep3ee/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1.50.0_babel-plugin_rkp5hcadkdur6dwkaz6svep3ee/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/42next-app-client/42next-app-client/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.10_@opentelemetry+api@1.8.0_@playwright+test@1.50.0_babel-plugin_rkp5hcadkdur6dwkaz6svep3ee/node_modules/next/dist/client/components/render-from-template-context.js");
